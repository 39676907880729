import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed today because of street closings
(including Main street from Story to Preston) 6am-12pm for the Color
Run.  East will be open for normal class hours at 10:00 & 11:00am and
there will be open gym from 12-2pm.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids today at East from 9:30-10:00.`}</em></strong></p>
    <p>{`Back Squats 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Glute Ham Raise 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`5-Pistols/Leg`}</p>
    <p>{`1-Rope Climb (15′)`}</p>
    <p>{`5-Back Squats (225/155)`}</p>
    <p>{`2-Rope Climbs`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      